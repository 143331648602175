// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Link } from 'gatsby'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'

import OutLink from '@bodhi-project/components/lib/OutLink'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'
import ContactForm from '../components/ContactForm'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Contact',
  nakedPageSlug: 'contact',
  pageAbstract:
    'Do you wish to meet us and have a look around? You can request a guided tour by contacting us. Please note that the appointment is subject to availability.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h2': {
    borderTop: '2.6px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },

  '& .map-etc': {
    paddingTop: 24,
    paddingBottom: 24,

    '& .address-and-contact': {
      paddingTop: 24,
      display: 'flex',

      '& .address': {
        flexGrow: 1,
        flexBasis: 50,
        paddingRight: 24,
      },

      '& .contact': {
        flexGrow: 1,
        flexBasis: 50,
        paddingLeft: 24,
      },
    },
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
const MapComponent = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: 31.291237, lng: 75.585443 }}
    >
      <Marker position={{ lat: 31.291237, lng: 75.585443 }} />
    </GoogleMap>
  ))
)

/** Page */
const Page = () => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1 className="mask-h3">Contact Us</h1>
    <div className="map-etc">
      <div className="address-and-contact">
        <div className="address">
          <h2 className="mask-h4">Write To Us</h2>
          <ContactForm />
          <h2 className="mask-h4">On The Map</h2>
          <MapComponent
            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDXoNnzaAoLMctYUbh966PLVDBlcTPpcWo"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
        <div className="contact">
          <h2 className="mask-h4">Address</h2>
          <p style={{ marginBottom: 10, marginTop: 0 }}>
            Urban Estate, Phase II
          </p>
          <p style={{ marginBottom: 10, marginTop: 0 }}>Jalandhar, 144002</p>
          <p style={{ marginBottom: 10, marginTop: 0 }}>Punjab</p>
          <p style={{ marginBottom: 30, marginTop: 0 }}>India</p>
          <h2 className="mask-h4">Email and Telephone</h2>
          <p style={{ marginBottom: 10, marginTop: 0 }}>
            General Enquiry:{' '}
            <OutLink to="mailto:enquiry@cambridgeinnovativejal.school">
              enquiry@cambridgeinnovativejal.school
            </OutLink>
          </p>
          <p style={{ marginBottom: 10, marginTop: 0 }}>
            Principal's Email:{' '}
            <OutLink to="mailto:principal@cambridgeinnovativejal.school">
              principal@cambridgeinnovativejal.school
            </OutLink>
          </p>
          
          <p style={{ marginBottom: 10, marginTop: 0 }}>
            Admissions Enquiry:{' '}
            <OutLink to="mailto:admissions@cambridgeinnovativejal.school">
              admissions@cambridgeinnovativejal.school
            </OutLink>,
            <br/>
            <OutLink to="mailto:pro@cambridgeinnovativejal.school">
             pro@cambridgeinnovativejal.school
            </OutLink>
          </p>
          <p style={{ marginBottom: 0, marginTop: 0 }}>
            Telephone: <OutLink to="tel:0181-4623951">0181-4623951</OutLink>,{' '}
            <OutLink to="tel:0181-4623952">0181-4623952</OutLink>,{' '}
            <OutLink to="tel:0181-4623952">0181-4623953</OutLink>
          </p>
        </div>
      </div>
    </div>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
